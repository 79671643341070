import { tap, takeUntil, timeout, catchError } from "rxjs/operators";
import { IPurchaseOrder } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnDestroy,
	Injector,
	OnInit,
} from "@angular/core";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "purchase-order-end",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderReallocateComponent
	extends GenericUpdateComponent<IPurchaseOrder>
	implements OnDestroy, OnInit
{
	schema = {
		title: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			user_confirm: {
				title: "I Understand",
				title_translation_key: marker("form_field_label_i_understand"),
				description: `This action will re-arrange allocations in order to assign shipped inventory information. Please verify after.`,
				type: "boolean",
				widget: "checkbox",
			},
		},
		required: ["user_confirm"],
	};

	submit_button = "Reallocate";

	private readonly purchase_order_id: number;
	private readonly is_outgoing: boolean;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.purchase_order_id = this._injector.get("purchase_order_id", undefined);
		this.is_outgoing = this._injector.get("is_outgoing", undefined);
		this.form_title = `Reallocate shipping order #${this.purchase_order_id} inventory`;
		this.form_title_translation_key = "";
		this.submit_button_translation_key = "";
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(_update) {
		this.loading$.next(true);
		this._itemService
			.update(
				`purchase_order`,
				`${this.purchase_order_id}/reallocate`,
				{
					is_outgoing: this.is_outgoing,
				},
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
