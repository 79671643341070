import { Page, Record } from "@elevatedsignals/amygoodman";

import { createPagingReducer } from "../createPagingReducer";
import { commonReducerSchema } from "./schema";

export const reducerKey = "vendor_related_records";

export type State = Page<Record>;

export const reducer = createPagingReducer<Record>(
	reducerKey,
	commonReducerSchema(true),
);
