import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
} from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { BillOfMaterialDetailQuery } from "app/shared/eagers";
import {
	IBillOfMaterialInput,
	IBillOfMaterialOutput,
} from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "bill-of-material-inventory-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BillOfMaterialInventoryUpdateComponent
	extends GenericUpdateComponent<IBillOfMaterialInput | IBillOfMaterialOutput>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			bill_of_material_id: {
				type: "number",
				title: "Bill of Material",
				title_translation_key: marker("word_bill_of_material"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "bill_of_materials",
					},
				],
			},
			destination: {
				type: "string",
				title: "Destination",
				title_translation_key: marker("word_destination"),
				widget: "select",
				oneOf: [
					{
						value: "Input",
						enum: ["Input"],
						name: "Input",
						name_translation_key: marker("word_input"),
					},
					{
						value: "Output",
						enum: ["Output"],
						name: "Output",
						name_translation_key: marker("word_output"),
					},
				],
			},
			input_type: {
				type: "string",
				title: "Type",
				title_translation_key: marker("word_type"),
				widget: "select",
				oneOf: [
					{
						value: "PLANTS",
						enum: ["PLANTS"],
						name: "Plants",
						name_translation_key: marker("word_plants"),
					},
					{
						value: "INVENTORY",
						enum: ["INVENTORY"],
						name: "Inventory",
						name_translation_key: marker("word_inventory"),
					},
				],
			},
			inventory_product_id: {
				type: "number",
				title: "Product",
				title_translation_key: marker("word_product"),
				widget: "data-select",
				selectFirstAndOnly: true,
				oneOf: [
					{
						result_type: "inventory_products",
					},
				],
				visibleIf: {
					input_type: ["INVENTORY"],
				},
			},
			sku_id: {
				type: "number",
				title: "SKU",
				title_translation_key: marker("word_sku"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "skus",
						text_key: ["sku"],
					},
				],
				visibleIf: {
					inventory_product_id: ["$EXP$ target.value > 0"],
				},
			},
			value: {
				widget: "number",
				type: "number",
				step: "1",
				title: `Quantity`,
				title_translation_key: marker("word_quantity"),
				visibleIf: {
					input_type: ["$ANY$"],
				},
				width: "50%",
			},
			inventory_unit_id: {
				type: "number",
				title: "Unit",
				title_translation_key: marker("word_unit"),
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_product_id"],
				selectFirstAndOnly: true,
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					input_type: ["INVENTORY"],
				},
				width: "50%",
			},
			multipliable: {
				title: "Apply Multiplier",
				title_translation_key: marker("form_field_label_apply_multiplier"),
				type: "boolean",
				widget: "checkbox",
				default: true,
			},
			plants: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					input_type: ["PLANTS"],
				},
			},
		},
		anyOf: [
			{
				required: [
					"bill_of_material_id",
					"inventory_product_id",
					"inventory_unit_id",
					"value",
				],
			},
			{
				required: ["bill_of_material_id", "plants", "value"],
			},
		],
	};

	private bill_of_material_id: number;
	private inventory: IBillOfMaterialInput | IBillOfMaterialOutput;
	private is_input: boolean | undefined;
	private prevInventoryProductId: number | undefined;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Update Inventory in a BOM";
		this.form_title_translation_key = marker(
			"form_title_update_inventory_in_a_bom",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.bill_of_material_id = this._injector.get(
			"bill_of_material_id",
			undefined,
		);
		this.inventory = this._injector.get("inventory", null);
		this.is_input = this._injector.get("is_input", undefined);
		this.model.bill_of_material_id = this.bill_of_material_id;
		this.model.id = this.inventory.id;
		this.model.input_type = this.inventory.plants ? "PLANTS" : "INVENTORY";
		this.model.inventory_product_id = this.inventory.inventory_product_id;
		this.model.sku_id = this.inventory.sku_id;
		this.model.inventory_unit_id = this.inventory.inventory_unit_id;
		this.model.multipliable = this.inventory.multipliable;
		this.model.destination = this._injector.get("inventory", null);
		this.model.value = this.inventory.quantity;

		if (this.bill_of_material_id) {
			this.schema.properties.bill_of_material_id = {
				...this.schema.properties.bill_of_material_id,
				default: this.bill_of_material_id,
				readOnly: true,
			};
		}
		if (this.is_input !== undefined) {
			this.schema.properties.destination = {
				...this.schema.properties.destination,
				default: this.is_input ? "Input" : "Output",
				readOnly: true,
			};
			this.model.destination = this.is_input ? "Input" : "Output";
			if (this.is_input) {
				this.form_title = "Update Input in BOM";
				// Make sure the changed title's translation key is also changed
				this.form_title_translation_key = marker("form_title_update_input_in_bom");
			} else {
				this.form_title = "Update Output in BOM";
				// Make sure the changed title's translation key is also changed
				this.form_title_translation_key = marker("form_title_update_output_in_bom");

				this.schema.properties.inventory_product_id.oneOf[0].queryString = {
					non_inventory_destruction: "false",
				};
			}
		}
		if (this.model.input_type) {
			this.schema.properties.input_type = {
				...this.schema.properties.input_type,
				default: this.model.input_type,
				readOnly: true,
			};
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;

			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.inventory_unit_id = inventory_product.display_unit_id;
					}
					this._cd.detectChanges();
				});
		}
	}

	updateItem(inventory) {
		this.loading$.next(true);
		this._itemService
			.update(
				`bill_of_material/${this.bill_of_material_id}/inventory`,
				inventory.id,
				inventory,
				BillOfMaterialDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "bill_of_materials",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
