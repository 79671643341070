import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
} from "@angular/core";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { IInventory, IPurchaseOrder } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { Globals } from "app/shared/modules/globals/globals.service";
import { dateIsBefore } from "app/shared/time-format";
import { ESValidator } from "app/shared/es-validator";
import { TranslocoService } from "@jsverse/transloco";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import { findRemotePOInventory } from "app/shared/utils/fetchRemotePOInventory";

import { NewLotCreationSchema } from "../lot/schemas";
import { SetInventoryStatusSchema } from "../work-order/schemas";

import { getDestinationInventory } from "./utils";

@Component({
	selector: "po-receive-inventory-order",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderReceiveInventoryComponent
	extends GenericUpdateComponent<IInventory>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			purchase_order_id: {
				type: "number",
				title: "Purchase Order",
				hidden: true,
			},
			inventory_product_id: {
				title: "Product",
				title_translation_key: marker("word_product"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: {
							non_zero_inventory: "false",
						},
					},
				],
				hidden: true,
			},
			amount_available: {
				title: "Amount Available for Receipt",
				title_translation_key: marker(
					"form_field_label_amount_available_for_receipt",
				),
				type: "string",
				widget: "string",
				readOnly: true,
			},
			receive_remaining: {
				title: "Receive Remaining Inventory",
				title_translation_key: marker(
					"form_field_label_receive_remaining_inventory",
				),
				type: "boolean",
				widget: "checkbox",
				default: false,
			},
			receive_remaining_true: {
				title: "Receiving Remaining Inventory",
				type: "boolean",
				widget: "checkbox",
				visibleIf: {
					allOf: [{ receive_remaining: true }],
				},
				hidden: true,
				default: true,
			},
			amount: {
				widget: "number",
				type: "number",
				step: "1",
				title: `Amount Received`,
				title_translation_key: marker("form_field_label_amount_received"),
				visibleIf: {
					allOf: [{ receive_remaining: false }],
				},
				width: "50%",
			},
			inventory_unit_id: {
				type: "number",
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					allOf: [
						{ receive_remaining: false },
						{ inventory_product_id: ["$EXP$ target.value > 0"] },
					],
				},
				width: "50%",
			},
			batch_id: {
				type: "number",
				title: "Batch",
				title_translation_key: marker("word_batch"),
				widget: "data-select",
				related_properties: [],
				oneOf: [
					{
						result_type: "batches",
						queryString: {
							input: "true",
							dont_show_expired: "true",
							non_zero_inventory: "false",
						},
					},
				],
			},
			location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				widget: "data-select",
				quick_create: false,
				related_properties: [],
				oneOf: [
					{
						result_type: "locations",
						queryString: {
							input: "true",
							non_zero_inventory: "false",
						},
					},
				],
			},
			sku_id: {
				type: "number",
				title: "SKU",
				title_translation_key: marker("word_sku"),
				widget: "data-select",
				quick_create: false,
				hidden: false,
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "skus",
						text_key: ["sku"],
					},
				],
				visibleIf: {
					inventory_product_id: ["$EXP$ target.value > 0"],
				},
			},
			new_lot: NewLotCreationSchema.new_lot,
			create_new_lot: NewLotCreationSchema.create_new_lot,
			lot_name: NewLotCreationSchema.lot_name,
			lot_id: NewLotCreationSchema.lot_id({ dont_show_expired: "true" }),
			new_inventory: {
				type: "string",
				title: "Inventory Tracking",
				title_translation_key: marker("form_field_label_inventory_tracking"),
				widget: "select",
				oneOf: [
					{
						value: "Create New Inventory",
						enum: ["Create New Inventory"],
						name: "Create New Inventory",
						name_translation_key: marker("form_field_value_create_new_inventory"),
					},
					{
						value: "Use Existing Inventory",
						enum: ["Use Existing Inventory"],
						name: "Use Existing Inventory",
						name_translation_key: marker("form_field_value_use_existing_inventory"),
					},
				],
				default: "Create New Inventory",
			},
			create_new_inventory: {
				type: "boolean",
				title: "Creating New Inventory",
				title_translation_key: marker("form_field_label_creating_new_inventory"),
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					new_inventory: ["Create New Inventory"],
				},
			},
			inventory_name: {
				type: "string",
				title: "New Inventory Name",
				description: "Generated if left blank",
				widget: "string",
				visibleIf: {
					new_inventory: ["Create New Inventory"],
				},
			},
			existing_inventory_id: {
				type: "number",
				title: "Inventory (optional)",
				title_translation_key: marker("form_field_label_inventory_optional"),
				widget: "data-select",
				quick_create: false,
				related_properties: [
					"location_id",
					"batch_id",
					"inventory_product_id",
					"lot_id",
				],
				oneOf: [
					{
						result_type: "inventories",
					},
				],
				visibleIf: {
					new_inventory: ["Use Existing Inventory"],
				},
			},
			timestamp: {
				type: "string",
				title: "Date",
				widget: "date",
				title_translation_key: marker("word_date"),
				warning: "The date must be after item was added to this order.",
				warning_translation_key: marker(
					"form_field_warning_the_date_must_be_after_item_was_added_to_this_order",
				),
			},
		},
		anyOf: [
			{
				required: ["purchase_order_id, amount"],
			},
		],
	};

	validators: Record<string, ESValidator> = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), new Date(this.minDate));
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	secondarySchema: any | undefined = undefined;
	secondaryModel: any = {};
	formSchema: any = {};
	eager = "[inventory_product, measurements.[debit_event.[inventory.[sku]]]]";

	statusSchema = SetInventoryStatusSchema;
	statusModel: any = {};

	private purchaseOrder: IPurchaseOrder;
	private pendingInventory: IInventory;
	private copiedInventory: IInventory | null;
	private dateValidatorFailed: boolean;
	private prevInventoryId: number | undefined;
	private requireBatch: boolean;
	private requireSku: boolean;
	private minDate: Date;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);
		this.form_title = "Inventory Received";
		this.form_title_translation_key = marker("form_title_inventory_received");
		this.submit_button = "Mark Received";
		this.submit_button_translation_key = marker("form_button_mark_received");

		this.schema.properties.amount.width = "50%";
		this.schema.properties.inventory_unit_id.width = "50%";
		this.schema.properties.inventory_unit_id.shorter_placeholder = true;
	}

	valid(valid) {
		this.valid$.next(valid);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}
		this.purchaseOrder = this._injector.get("purchase_order", false);
		this.pendingInventory = this._injector.get("pending_inventory", false);
		this.copiedInventory = this._injector.get("copied_inventory", false);
		const timestamp = this._injector.get("timestamp", null);
		this.minDate = new Date(this.purchaseOrder.po_date);
		const destinationInventory = getDestinationInventory(
			this.pendingInventory,
			this.copiedInventory,
		);
		const amount =
			(this.pendingInventory.totalAmount ?? 0) > 0
				? this.pendingInventory.totalAmount
				: undefined;
		const amount_available =
			amount && amount > 0
				? `${amount} ${this.pendingInventory.inventory_unit?.name}`
				: `All ordered inventory received.`;
		this.model = {
			purchase_order_id: this.purchaseOrder.id,
			inventory_product_id: this.pendingInventory.inventory_product_id,
			amount_available,
			...(amount && { amount }),
			inventory_unit_id: this.pendingInventory.inventory_unit_id,
			location_id: destinationInventory?.location_id,
			batch_id: destinationInventory?.batch_id,
			sku_id: destinationInventory?.sku?.id,
			...(destinationInventory?.lot_id && {
				lot_id: destinationInventory.lot_id,
				create_new_lot: false,
				new_lot: "Use Existing Lot",
			}),
			...(destinationInventory && {
				existing_inventory_id: destinationInventory.id,
				new_inventory: "Use Existing Inventory",
			}),
			...(timestamp && { timestamp }),
		};

		// If we've already received all inventory, don't show receive_remaining
		if (!amount) {
			this.schema.properties.receive_remaining.hidden = true;
		}

		if (this.model.batch_id) {
			this.schema.properties.lot_id.related_properties = ["batch_id"];
		}

		const inventoryProduct = this.pendingInventory.inventory_product;
		if (inventoryProduct) {
			this.requireBatch = inventoryProduct.require_batch;
			this.requireSku = inventoryProduct.require_sku;

			if (this.requireBatch) {
				this.schema.properties.batch_id.hidden = false;
			} else {
				this.schema.properties.batch_id.hidden = true;
			}

			this.getCustomFields(this.pendingInventory.inventory_product?.uuid)
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					catchError(() => {
						this.secondaryModel = {};
						this.secondarySchema = undefined;
						this._cd.detectChanges();
						return EMPTY;
					}),
				)
				.subscribe((item) => {
					this.formSchema = JSON.parse(JSON.stringify(item));
					if (Object.keys(this.formSchema?.schema?.properties).length) {
						this.secondarySchema = {
							title: this.formSchema.name ?? "Product Fields",
							type: "object",
							properties: {
								...this.formSchema.schema.properties,
							},
							...(this.formSchema.schema.required && {
								required: [...this.formSchema.schema.required],
							}),
						};
						if (this.formSchema?.model?.length > 0) {
							this.secondaryModel = this.formSchema.model[0].field_model;
						}
					} else {
						this.secondarySchema = undefined;
					}
					this._cd.detectChanges();
				});
		}

		const requiredAdditions = [
			...(this.requireBatch ? ["batch_id"] : []),
			...(this.requireSku ? ["sku_id"] : []),
		];
		this.schema.anyOf = [
			{
				required: [
					"location_id",
					"purchase_order_id",
					"create_new_lot",
					"amount",
					...requiredAdditions,
				],
			},
			{
				required: [
					"location_id",
					"purchase_order_id",
					"lot_id",
					"amount",
					...requiredAdditions,
				],
			},
			{
				required: [
					"location_id",
					"purchase_order_id",
					"create_new_lot",
					"receive_remaining_true",
					...requiredAdditions,
				],
			},
			{
				required: [
					"location_id",
					"purchase_order_id",
					"lot_id",
					"receive_remaining_true",
					...requiredAdditions,
				],
			},
		];
		// if (receiveAll) {
		// 	delete this.schema.properties.inventory_unit_id;
		// }

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (!model.existing_inventory_id && this.prevInventoryId) {
			this.prevInventoryId = undefined;
			delete this.secondaryModel;
			this.selectExistingInventory(undefined);
		} else if (
			model.existing_inventory_id &&
			this.prevInventoryId !== model.existing_inventory_id
		) {
			this.prevInventoryId = model.existing_inventory_id;

			this._itemService
				.fetchItem(`inventory`, `${model.existing_inventory_id}`, {
					eager: this.eager,
				})
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory) => {
					this.selectExistingInventory(inventory);
					this.getCustomFields(
						inventory.inventory_product.uuid,
						model.existing_inventory_id,
					)
						.pipe(takeUntil(this.destroyed$))
						.pipe(
							catchError(() => {
								this.secondaryModel = {};
								this.secondarySchema = undefined;
								this._cd.detectChanges();
								return EMPTY;
							}),
						)
						.subscribe((item) => {
							this.formSchema = JSON.parse(JSON.stringify(item));
							if (Object.keys(this.formSchema?.schema?.properties).length) {
								this.secondarySchema = {
									title: this.formSchema.name ?? "Product Fields",
									type: "object",
									properties: {
										...this.formSchema.schema.properties,
									},
									...(this.formSchema.schema.required && {
										required: [...this.formSchema.schema.required],
									}),
								};
								if (this.formSchema?.model?.length > 0) {
									this.secondaryModel = this.formSchema.model[0].field_model;
								}
							} else {
								this.secondarySchema = undefined;
							}
							this._cd.detectChanges();
						});
				});
		}
	}

	selectExistingInventory(inventory: IInventory | undefined) {
		const inventorySelected = Boolean(inventory);
		this.schema.properties.batch_id.readOnly = inventorySelected;
		this.schema.properties.location_id.readOnly = inventorySelected;
		this.schema.properties.sku_id.readOnly = inventorySelected;
		this.schema.properties.lot_id.readOnly = inventorySelected;
		this.schema.properties.new_lot.readOnly = inventorySelected;
		this.model = {
			...this.model,
			batch_id: inventory?.batch_id,
			location_id: inventory?.location_id,
			sku_id: inventory?.sku?.id,
			lot_id: inventory?.lot_id,
			...(inventory?.lot_id && {
				create_new_lot: false,
				new_lot: "Use Existing Lot",
			}),
		};
	}

	updateItem(received) {
		let update: any = {
			...received,
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
			status_id: this.statusSchema ? this.statusModel.status_id : undefined,
		};

		// Create Custom Field Model
		if (this.secondarySchema) {
			const custom_fields = {
				custom_field_schema_id: this.formSchema.id,
				type: this.formSchema.type,
				jsonModel: this.secondaryModel,
				id:
					this.formSchema.model && this.formSchema.model.length
						? this.formSchema.model[0]?.id
						: undefined,
			};

			update = {
				...update,
				custom_fields,
			};
		}

		this.loading$.next(true);
		this._itemService
			.update(
				`purchase_order/${this.purchaseOrder.id}/inventory`,
				`${this.pendingInventory.id}/receive`,
				update,
				{
					...PurchaseOrderDetailQuery,
				},
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	getCustomFields(type, entity_id = ""): any {
		return this._itemService.fetchItem("custom_field_model", `${type}`, {
			entity_id: entity_id.toString(),
		});
	}
}
