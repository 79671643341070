import { marker } from "@jsverse/transloco-keys-manager/marker";
import { HeaderQueryType } from "@elevatedsignals/amygoodman";

export const ReducerKey = {
	Batches: "batches",
	BatchGi: "batch_gi",
	BatchNonGi: "batch_non_gi",
	RelatedBatches: "related_batches",
	Locations: "locations",
	Plants: "plants",
	DestructionReasons: "destruction_reasons",
} as const;

export const customReducerKeys = [
	ReducerKey.BatchGi,
	ReducerKey.BatchNonGi,
	"destruction_events_batch_gi",
	"destruction_events_batch_non_gi",
	"jobs", // printer_jobs
	"plant_related_records",
	"equipment_related_records",
	"location_related_records",
	"batch_related_records",
	"vendor_related_records",
	"reporting_periods",
];

export const SortingHeaderQuery = {
	[ReducerKey.Locations]: {
		title: "Sort by",
		key: "sortBy",
		title_translation_key: marker("location_filter_sort"),
		type: HeaderQueryType.select,
		options: [
			{
				label: "Alphabetical",
				translationKey: marker("location_filter_sort_alphabetical"),
				value: "alphabetical",
			},
			{
				label: "Created at",
				translationKey: marker("location_filter_sort_created_at"),
				value: "created_at",
			},
		],
		value: "created_at",
	},
	[ReducerKey.Batches]: {
		title: "Order By",
		title_translation_key: marker("batch_filter_sort"),
		key: "orderBy",
		type: HeaderQueryType.select,
		options: [
			{
				label: "Created At",
				translationKey: marker("batch_filter_sort_created"),
				value: "created_at",
			},
			{
				label: "# of Plants",
				translationKey: marker("batch_filter_sort_plants"),
				value: "plant_count",
			},
			{
				label: "Alphabetical",
				translationKey: marker("batch_filter_sort_alphabetical"),
				value: "alphabetical",
			},
		],
		value: "created_at",
	},
	[ReducerKey.Plants]: {
		title: "Order By",
		title_translation_key: marker("plant_filter_sort"),
		key: "sortBy",
		type: HeaderQueryType.select,
		options: [
			{
				label: "Alphabetical",
				translationKey: marker("plant_filter_sort_alphabetical"),
				value: "alphabetical",
			},
			{
				label: "Id",
				translationKey: marker("plant_filter_sort_id"),
				value: "id",
			},
			{
				label: "Created At",
				translationKey: marker("plant_filter_sort_created_at"),
				value: "created_at",
			},
		],
		value: "created_at",
	},
	[ReducerKey.DestructionReasons]: {
		title: "Sort by",
		key: "sortBy",
		title_translation_key: marker("filter_sort_by"),
		type: HeaderQueryType.select,
		options: [
			{
				label: "Alphabetical",
				translationKey: marker("filter_sort_alphabetical"),
				value: "alphabetical",
			},
			{
				label: "Created at",
				translationKey: marker("filter_sort_created_at"),
				value: "created_at",
			},
		],
		value: "created_at",
	},
};
