import { marker } from "@jsverse/transloco-keys-manager/marker";

export const WorkOrderPlantSourceByLocationSchema = {
	title: "",
	description: "",
	info: "",
	properties: {
		id: {
			type: "number",
			hidden: true,
		},
		plant_location_id: {
			type: "number",
			title: "Source Location",
			title_translation_key: marker("form_field_label_source_location"),
			widget: "data-select",
			oneOf: [{ result_type: "locations" }],
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
			hidden: false,
		},
		move_batch_to_work_order_location: {
			type: "boolean",
			widget: "checkbox",
			title: "Move batch to work order location",
			title_translation_key: marker(
				"form_field_label_move_batch_to_work_order_location",
			),
			hidden: true,
		},
	},
	anyOf: [
		{
			required: ["id", "plant_location_id"],
		},
	],
};
