import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { translate } from "@jsverse/transloco";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import { IInventory, IPurchaseOrder } from "@elevatedsignals/amygoodman";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ESValidator } from "app/shared/es-validator";
import { dateIsBefore } from "app/shared/time-format";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";
import {
	getDynamicFormChanges,
	fetchNewInventoryTotals,
	hasInventoryIdsChanged,
	hasPropertyChanged,
} from "../shared";

@Component({
	selector: "so-ship-inventory",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ShippingOrderShipInventoryComponent
	extends GenericCreateComponent<IInventory>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			purchase_order_id: {
				type: "number",
				title: "Purchase Order",
				hidden: true,
			},
			amount_available: {
				title: "Amount Available for Shipment",
				title_translation_key: marker(
					"form_field_label_amount_available_for_shipment",
				),
				type: "string",
				widget: "string",
				readOnly: true,
			},
			ship_remaining: {
				title: "Ship Remaining Inventory",
				title_translation_key: marker("form_field_label_ship_remaining_inventory"),
				type: "boolean",
				widget: "checkbox",
				default: false,
			},
			ship_remaining_true: {
				title: "Shipping Remaining Inventory",
				type: "boolean",
				widget: "checkbox",
				visibleIf: {
					allOf: [{ ship_remaining: true }],
				},
				hidden: true,
				default: true,
			},
			amount: {
				type: "number",
				title: `Amount`,
				visibleIf: {
					allOf: [{ ship_remaining: false }],
				},
				width: "50%",
			},

			timestamp: {
				type: "string",
				title: "Date",
				widget: "date",
				title_translation_key: marker("word_date"),
				warning: "The date must be after Allocation Date.",
				warning_translation_key: marker(
					"form_field_warning_the_date_must_be_after_allocation_date",
				),
			},
		},
		anyOf: [
			{
				required: ["purchase_order_id", "amount"],
			},
			{
				required: ["purchase_order_id", "ship_remaining_true"],
			},
		],
	};

	validators: Record<string, ESValidator> = {
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), this.minDate);
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	private purchase_order: IPurchaseOrder;
	private inventory: IInventory;
	private minDate: Date;
	private remainingTotal: number;
	private shipAllAllocations: boolean;

	private dateValidatorFailed: boolean;
	private readonly whatChanged: SimpleChanges = {};

	constructor(
		protected readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
	) {
		super(_store);
		this.form_title = `Ship Inventory in Order`;
		this.form_title_translation_key = marker(
			"form_title_ship_inventory_in_order",
		);

		this.submit_button = "Ship";
		this.submit_button_translation_key = marker("word_ship");
		this.submit_icon = "truck";
	}

	valid(valid) {
		this.valid$.next(valid);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		this.purchase_order = this._injector.get("purchase_order", null);
		this.inventory = this._injector.get("inventory", null);
		this.remainingTotal = this._injector.get("remainingTotal", null);
		this.shipAllAllocations = this._injector.get("shipAllAllocations", false);

		this.minDate = new Date(
			this.inventory.measurements?.[0]?.timestamp ?? this.inventory.created_at,
		);

		this.model = {
			purchase_order_id: this.purchase_order.id,
			amount_available: `${this.remainingTotal} ${this.inventory.inventory_unit?.name}`,
		};

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.schema.properties.amount = {
			...this.schema.properties.amount,
			title: `${translate("word_amount")} (${this.inventory.inventory_unit?.name})`,
		};

		this.schema.properties.purchase_order_id = {
			...this.schema.properties.purchase_order_id,
			default: this.purchase_order.id,
			readOnly: true,
		};

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		getDynamicFormChanges(this.whatChanged, model);
		this.checkAndUpdateInventoryTotals();
	}

	checkAndUpdateInventoryTotals = () => {
		if (
			hasPropertyChanged(this.whatChanged.inventory_product_id) ||
			hasPropertyChanged(this.whatChanged.lot_id) ||
			hasPropertyChanged(this.whatChanged.batch_id) ||
			hasInventoryIdsChanged(this.whatChanged.inventory_ids) ||
			hasPropertyChanged(this.whatChanged.timestamp) ||
			hasPropertyChanged(this.whatChanged.vendor_id)
		) {
			fetchNewInventoryTotals(this.whatChanged, this._itemService).subscribe(
				(availableInventoryAmount) => {
					this.model.amount_available = availableInventoryAmount.content
						.map((availableInventory) => {
							return `${availableInventory.sum.toFixed(2)} ${availableInventory.name}`;
						})
						.join("\n");

					this._cd.detectChanges();
				},
			);
		}
	};

	createItem(shipment) {
		this.loading$.next(true);
		this._itemService
			.add(
				`purchase_order/${this.purchase_order.id}/inventory/${this.inventory.id}/ship`,
				{ ...shipment, ship_all_allocations: this.shipAllAllocations },
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem: IPurchaseOrder) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);

					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
