import {
	HeaderQueryType,
	IDestructionReason,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { DestructionReasonPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";
import { ReducerKey, SortingHeaderQuery } from "./keys";

export const reducerKey = ReducerKey.DestructionReasons;

export type State = Page<IDestructionReason>;

export const reducer = createPagingReducer<IDestructionReason>(reducerKey, {
	result_type: "destruction_reasons",
	title: "Destruction Reason",
	title_plural: "Destruction Reasons",
	title_translation_key: marker("page_title_destruction_reasons"),
	title_plural_translation_key: marker("page_title_plural_destruction_reasons"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Show Archived",
			title_translation_key: marker("checkbox_filter_show_archived"),
			key: "showArchived",
			type: HeaderQueryType.checkbox,
			value: false,
		},
		SortingHeaderQuery[reducerKey],
	],
	query: DestructionReasonPageQuery,
});
