import { HeaderQueryType, Page, Plant } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";
import { ReducerKey, SortingHeaderQuery } from "./keys";

export const reducerKey = ReducerKey.Plants;

export type State = Page<Plant>;

export const reducer = createPagingReducer<Plant>(reducerKey, {
	result_type: "plants",
	title: "Plant",
	title_translation_key: marker("page_title_plants"),
	title_plural: "Plants",
	title_plural_translation_key: marker("page_title_plural_plants"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Plant Status",
			title_translation_key: marker("plant_filter_status"),
			key: "plant_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Active Plants",
					translationKey: marker("plant_filter_active_plants"),
					value: "ACTIVE",
				},
				{
					label: "Harvested Plants",
					translationKey: marker("plant_filter_harvested_plants"),
					value: "HARVESTED",
				},
				{
					label: "Destroyed Plants",
					translationKey: marker("plant_filter_destroyed_plants"),
					value: "DESTROYED",
				},
				{
					label: "Archived Plants",
					translationKey: marker("plant_filter_archived_plants"),
					value: "ARCHIVED",
				},
				{
					label: "All Plants",
					translationKey: marker("plant_filter_all_plants"),
					value: "ALL",
				},
			],
			value: "ACTIVE",
		},
		{
			title: "Plant Filter",
			title_translation_key: marker("plant_filter_gender"),
			key: "plant_filter",
			type: HeaderQueryType.select,
			search: true,
			options: [
				{
					label: "Mother Plant",
					translationKey: marker("plant_filter_mother_plant"),
					value: "MOTHER",
				},
				{
					label: "Male Plant",
					translationKey: marker("plant_filter_male_plant"),
					value: "MALE",
				},
			],
		},
		{
			title: "Location",
			title_translation_key: marker("word_location"),
			key: "plant_location",
			type: HeaderQueryType.select,
			search: true,
			options: [
				{
					label: "Batch",
					translationKey: marker("word_batch"),
					value: "BATCH",
				},
				{
					label: "Location Only",
					translationKey: marker("plant_location_filter_location_only"),
					value: "LOCATION",
				},
			],
			required_facility_settings: { disable_plant_in_location: false },
		},
		{
			title: "Lot",
			title_translation_key: marker("page_title_lots"),
			key: "lot_id",
			type: HeaderQueryType.dataSelect,
			result_type: "lots",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: false,
			search: true,
			related_key: "plant_batch_id",
		},
		{
			title: "Tags",
			title_translation_key: marker("page_title_plural_tags"),
			key: "tag_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "tags",
			value_key: "id",
			text_key: ["name"],
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		SortingHeaderQuery[reducerKey],
	],
});
