import {
	HeaderQueryType,
	IPurchaseOrder,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { PurchaseOrderPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "purchase_orders";

export type State = Page<IPurchaseOrder>;

export const reducer = createPagingReducer<IPurchaseOrder>(reducerKey, {
	result_type: "purchase_orders",
	title: "Purchase Order",
	title_plural: "Purchase Orders",
	title_translation_key: marker("page_title_purchase_orders"),
	title_plural_translation_key: marker("page_title_plural_purchase_orders"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Status Filter",
			key: "order_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Open",
					translationKey: marker("word_open"),
					value: "OPEN",
				},
				{
					label: "Closed",
					translationKey: marker("word_closed"),
					value: "CLOSED",
				},
				{
					label: "Archived",
					translationKey: marker("word_archived"),
					value: "ARCHIVED",
				},
				{
					label: "All",
					translationKey: marker("word_all"),
					value: "ALL",
				},
			],
			value: "OPEN",
		},
		{
			title: "Vendors",
			title_translation_key: marker("page_title_plural_vendors"),
			key: "vendor_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "vendors",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
	],
	query: PurchaseOrderPageQuery,
});
